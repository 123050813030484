import { Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import Tree from 'react-d3-tree';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Load from '../../examples/Load';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import axios from 'axios';
import { url_serversq } from "../../context/var";
import MDButton from '../../components/MDButton';
import MDSnackbar from '../../components/MDSnackbar';
import MDInput from '../../components/MDInput';
import styled from '@emotion/styled';
import { useMaterialUIController } from '../../context';
import { t } from 'ttag';
import RefData from './refdata';
import PoolRefData from './poolrefdata';

function Structure() {
    const [orgChart, setorgChart] = useState(() => {
        return false
    });

    const [controller, dispatch] = useMaterialUIController();
    const {
        darkMode,
    } = controller;
    const [period, setPeriod] = useState(() => { return -1 });

    if (!orgChart) {
        let s_key = localStorage.getItem('s_key');
        axios.post(url_serversq + "/getStructure.php", {
            s_key: s_key,
            period: period,
        }).then(res => {
            //console.log(res.data);
            if (res.data != false) {
                setPeriod(res.data.periods)
                setorgChart(res.data);
            } else {
                //alert(t`Ошибка связи с сервером`);
                window.location.href = "/"
            }
        }).catch(() => {
            //alert(t`Ошибка связи с сервером`);
            window.location.href = "/"
        })
    }
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [infoSB, setInfoSB] = useState(false);
    const renderInfoSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title={t`Информация`}
            content={t`Реферальная ссылка скопирована в буфер обмена`}
            dateTime=""
            open={infoSB}
            onClose={closeInfoSB}
            close={closeInfoSB}
        />
    );
    const handleChange = (event) => {
        setPeriod(event.target.value);
        setorgChart(false);
        //setT(packs.find(el => el.id == event.target.value));
        //setDas(false);
    };

    const copyLink = event => {
        event.persist()

        navigator.clipboard.writeText(orgChart.copyLink)
            .then(() => {

            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
        openInfoSB();
    }
    {/* <p style={{ textAlign: "right" }}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'USD' }).format(nodeDatum.deposite ? nodeDatum.deposite : 0)}</p> */ }

    if (orgChart) {
        const renderForeignObjectNode = ({
            nodeDatum,
            toggleNode,
            foreignObjectProps
        }) => (
            <g>

                {/* `foreignObject` requires width & height to be explicitly set. */}
                <foreignObject {...foreignObjectProps} x={-90} y={-50} onClick={toggleNode}>
                    <div style={{ border: "1px solid black", backgroundColor: "rgb(255 188 188)", borderRadius: '10px', minHeight: '3em' }} >
                        <p style={{ textAlign: "center", fontSize: "14px" }}>{nodeDatum.name}</p>
                        {nodeDatum.children && (
                            <button style={{ width: "100%" }} onClick={toggleNode}>
                                {nodeDatum.__rd3t.collapsed ? t`Показать` : t`Скрыть`}
                            </button>
                        )}
                    </div>
                </foreignObject>
            </g>
        );
        const nodeSize = { x: 180, y: 190 };
        const foreignObjectProps = { width: nodeSize.x - 5, height: nodeSize.y };
        let s_key = localStorage.getItem('s_key');

        const StyledTableCell = darkMode ? styled(TableCell)(({ theme }) => ({
            color: '#FFFF',
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: "#ffa804",
                color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        })) : styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: "#ffa804",
                color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
        }));
        var rows = [];

        if (orgChart.isArpio) {
            rows = [
                { name: t`1-я линия`, vol: '10%' },
                { name: t`2-я линия`, vol: '5%' },
                { name: t`3-я линия`, vol: '4%' },
                { name: t`4-я линия`, vol: '2%' },
                { name: t`5-я линия`, vol: '2%' },
                { name: t`6-я линия`, vol: '1%' },
                { name: t`7-я линия`, vol: '1%' },

            ];
        } else {
            rows = [
                { name: t`1-я линия`, vol: '15%' },
                { name: t`2-я линия`, vol: '10%' },
                { name: t`3-я линия`, vol: '5%' },
                { name: t`4-я линия`, vol: '2.5%' },
                { name: t`5-я линия`, vol: '2.5%' },
                { name: t`6-я линия`, vol: '2.5%' },
                { name: t`7-я линия`, vol: '2.5%' },
                { name: t`8-я линия`, vol: '1.5%' },
                { name: t`9-я линия`, vol: '1.5%' },
                { name: t`10-я линия`, vol: '1.5%' },
                { name: t`11-я линия`, vol: '1.5%' },
                { name: t`12-я линия`, vol: '1%' },
                { name: t`13-я линия`, vol: '1%' },
                { name: t`14-я линия`, vol: '1%' },
                { name: t`15-я линия`, vol: '1%' },
            ];
        }
        var mi = [];
        orgChart.period.forEach(element => {
            mi.push(<MenuItem key={"m1" + element.id} value={element.id}>{element.name}</MenuItem>)
        });

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} >
                        <Card sx={{ height: "100%" }}>
                            <MDBox p={3}>
                                <MDTypography variant="h6" fontWeight="medium" pb={2}>
                                    {t`Ссылки`}
                                </MDTypography>
                                <MDBox component="form" role="form">
                                    <MDBox mb={2} style={{ display: "flex", alignItems: "center", }} >
                                        <MDInput type="text" label={t`Реферальная ссылка`} fullWidth value={orgChart.copyLink} disable="true" /> &nbsp;&nbsp;&nbsp;
                                        <MDButton variant="gradient" color="info" onClick={copyLink}  >{t`ваша реферальная ссылка`} </MDButton>
                                    </MDBox>
                                </MDBox>
 {/*                                <MDBox component="form" role="form">
                                    <MDBox mb={2} style={{ display: "flex", alignItems: "center", }} >
                                        <MDInput type="text" label={t`Для регистрации в RoboForex`} fullWidth value={orgChart.robolink} disable="true" /> &nbsp;&nbsp;&nbsp;
                                        <MDButton variant="gradient" color="info" href={orgChart.robolink} target="_blank"  >{t`регистрация в RoboForex`} </MDButton>
                                    </MDBox>
                                </MDBox>
 */}                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Card sx={{ height: "100%" }}>
                            <MDBox >
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead style={{ padding: 0, display: "contents" }}>
                                            <TableRow>
                                                <StyledTableCell align="center">{t`Линия`}</StyledTableCell>
                                                <StyledTableCell align="center">{t`Вознаграждение`}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <StyledTableRow key={row.name}>
                                                    <StyledTableCell align="center" style={{ padding: 7 }}>{row.name}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.vol}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12}  >
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <InputLabel id="period-select-label">{t`Период`}</InputLabel>
                            <Select
                                sx={{ minHeight: '3em' }}
                                labelId="period-select-label"
                                value={period}
                                label={t`Период`}
                                onChange={handleChange}
                                autoWidth
                            >
                                {mi}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}  >
                        <RefData orgChart={orgChart} />
                    </Grid>

   {/*                  {orgChart.isArpio ? null :
                        <Grid item xs={12}  >
                            <PoolRefData orgChart={orgChart} />
                        </Grid>
                    }
 */}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <MDBox mb={1} style={{ height: '80vh', width: '100%' }}>
                            <iframe title="Tree" src={url_serversq + "/tree.php?s_key=" + s_key} style={{ width: '100%', height: '100%', border: 'none' }} />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Card >
                            <MDBox id='e4557' style={{ height: '80vh', width: '100%' }}>
                                <Tree
                                    data={orgChart.str}
                                    pathFunc="step"
                                    nodeSize={nodeSize}
                                    translate={{
                                        x: 300,
                                        y: 200
                                    }}
                                    initialDepth={1}
                                    renderCustomNodeElement={(rd3tProps) =>
                                        renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                                    }
                                    orientation="vertical"
                                />
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
                {renderInfoSB}
                <Footer />
            </DashboardLayout>


        );
    } else {
        return (<div style={{
            width: '100vw',
            height: '100vh',
        }}><Load /></div>);
    }
}


export default Structure;

{/* <Tree
orientation="vertical"
collapsible={true}
useCollapseData={true}
initialDepth={1}
nodeSize={{ x: 180, y: 180 }}
transitionDuration={700}
zoomable={true}

data={orgChart} /> */}